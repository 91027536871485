import header from "@/assets/table/greigeStock/greigeStock";
export default {
  components: {},
  data() {
    return {
      header,
    };
  },
  computed: {},
  methods: {},
  created() {},
};
