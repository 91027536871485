<template>
  <div class="stockGridTable stockGridTableTbody">
    <div
      v-for="head in header"
      :key="head._id"
      class="d-flex"
      :class="head.value === 'check' ? 'bodyItemLeft' : 'bodyItem'"
    >
      <div v-if="head.value === 'date'" style="width: 100%">
        <v-menu
          ref="menu_date_register"
          v-model="menu_date_register"
          :close-on-content-click="false"
          :return-value.sync="newStock.date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="newStock.date"
              v-on="on"
              dense
              :attrs="attrs"
              v-bind="attrs"
              hide-details
              readonly
              class="centered-input"
            ></v-text-field>
          </template>
          <DatePicker
            v-model="newStock.date"
            @input="$refs.menu_date_register[0].save(newStock.date)"
          >
          </DatePicker>
        </v-menu>
      </div>

      <div v-if="head.value === 'kind'" style="width: 100%">
        <v-select
          v-model="newStock.kind"
          :items="stockType"
          @change="kindChange($event)"
          item-text="title"
          item-value="value"
          hide-details
          return-object
          dense
          style="padding-top: 2px"
        />
      </div>
      <div v-if="head.value === 'order'" style="width: 100%">
        <v-autocomplete
          return-object
          v-model="newStock.order"
          dense
          hide-details
          :items="orderList"
          item-text="order"
          class="centered-input"
          @change="orderChange($event)"
        >
        </v-autocomplete>
      </div>
      <div v-if="head.value === 'item'" style="width: 100%">
        <DenseSubItemComboBox
          v-model="newStock.item"
          :items="$store.state.itemList"
          item-text="name"
          @click="openEditDialog(newStock.item, 'new', 'Item')"
          :dense="true"
          flat
        />
      </div>
      <div v-if="head.value === 'client'" style="width: 100%">
        <v-autocomplete
          return-object
          v-model="newStock.client"
          dense
          hide-details
          :items="$store.state.useClientList"
          item-text="name"
          class="centered-input"
        >
        </v-autocomplete>
      </div>
      <div v-if="head.value === 'name'" style="width: 100%">
        <v-text-field hide-details dense v-model="newStock.color">
        </v-text-field>
      </div>
      <div v-if="head.value === 'unit_price'" style="width: 100%">
        <div class="pt-0 pb-0">
          <v-text-field
            hide-details
            dense
            v-model="newStock.unit_price"
            @input="(e) => (newStock.unit_price = usingComma(e))"
          >
            <template v-slot:prepend-inner>
              <v-select
                :items="$store.state.currency_sign"
                item-text="label"
                item-value="value"
                v-model="newStock.currency_sign"
                hide-details
                flat
                solo
                hide-selected
                class="amountSelect pt-0 pr-0"
                id="claimCurrency"
                ref="salesClaimTable"
              >
                <template v-slot:selection="{ item }">
                  <span>
                    {{ item.label }}
                  </span>
                </template>
              </v-select>
            </template>
          </v-text-field>
          <div v-if="newStock.currency_sign !== '₩'" class="eschamgeCell">
            <v-text-field
              hide-details
              label="환율"
              v-model="newStock.eschamge"
              @input="(e) => (newStock.eschamge = usigComnma(e))"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <div v-if="head.value === 'stock_quantity'" style="width: 100%">
        <v-text-field
          hide-details
          dense
          v-model="newStock.stock_quantity"
          @input="(e) => (newStock.stock_quantity = usingComma(e))"
        >
          <template v-slot:append>
            <v-select
              :items="$store.state.unit_sign"
              item-text="label"
              item-value="value"
              v-model="newStock.unit_sign"
              hide-details
              flat
              solo
              hide-selected
              class="amountSelect pt-0 pr-0"
            >
              <template v-slot:selection="{ item }">
                <span>
                  {{ item.label }}
                </span>
              </template>
            </v-select>
          </template>
        </v-text-field>
      </div>
      <!-- <div v-if="head.value === 'remain_quantity'" style="width: 100%">
        <v-text-field
          hide-details
          dense
          v-model="newStock.remain_quantity"
          @input="(e) => (newStock.remain_quantity = usingComma(e))"
        >
          <template v-slot:append>
            <v-select
              :items="$store.state.unit_sign"
              item-text="label"
              item-value="value"
              v-model="newStock.unit_sign"
              hide-details
              flat
              solo
              hide-selected
              class="amountSelect pt-0 pr-0"
            >
              <template v-slot:selection="{ item }">
                <span>
                  {{ item.label }
                </span>
              </template>
            </v-select>
          </template>
        </v-text-field>
      </div> -->
      <div v-if="head.value === 'status'">등록</div>
      <div v-if="head.value === 'edit'" style="width: 100%">
        <div class="twoGridColumn">
          <div>
            <v-btn icon @click="clearRow" :disabled="$store.state.isExpired">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </div>
          <div>
            <v-btn @click="saveStock" icon :disabled="$store.state.isExpired">
              <v-icon> mdi-check</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import header from "@/assets/table/stock/stock.js";
import DatePicker from "@/components/atoms/Vdate/DatePicker.vue";
import DenseSubItemComboBox from "@/components/atoms/VComboBox/DenseSubItemComboBox.vue";
// import DenseLabelSelect from "@/components/atoms/VSelect/DenseLabelSelect.vue";

import { mapGetters } from "vuex";
export default {
  components: {
    DatePicker,
    DenseSubItemComboBox,
    // DenseLabelSelect,
  },
  computed: {
    ...mapGetters({
      newStock: "getNewStock",
      orderList: "getClaimOrderList",
    }),
  },
  data() {
    return {
      header,
      menu_date_register: false,
      stockType: [
        { title: "일반", value: 3 },
        { title: "생지", value: 4 },
      ],
    };
  },
  methods: {
    clearRow() {
      this.$store.commit("setNewStock");
    },
    saveValidate(data) {
      if (!data.item) {
        this.$store.commit("setSnackBar", `아이템을 선택해주세요.`);
        return false;
      }
      if (!data.stock_quantity) {
        this.$store.commit("setSnackBar", `재고수량을 입력해주세요.`);
        return false;
      }
      return true;
    },

    saveStock() {
      if (!this.saveValidate(this.newStock)) {
        return false;
      }
      this.$store.dispatch("TASK_SAVE_STOCK", {
        data: this.newStock,
        type: "save",
      });
    },
    orderChange(event) {
      if (event) {
        this.newStock.item = this.$store.state.itemList.find(
          (x) => x._id === event.item,
        );
        this.newStock.client = this.$store.state.clientList.find(
          (x) => x._id === event.client,
        );
      }
    },
    kindChange(data) {
      this.newStock.kind = data;
    },
    openEditDialog(item, target, type) {
      this.$store.commit("setEditedItemForm");
      this.editForm = { ...item };
      this.editForm.type = type;

      this.editForm.target = target;
      this.editForm.business_num
        ? (this.editForm.business_num = this.getBusinessNumMask(
            this.editForm.business_num,
          ))
        : "";

      this.editForm.tel
        ? (this.editForm.tel = this.getPhoneMask(this.editForm.tel))
        : "";

      this.$store.commit("setEditedItemForm", this.editForm);
      this.$store.commit("setDailogStatus", {
        status: true,
        kind: "setting_form",
      });
    },
  },
};
</script>
