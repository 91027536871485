export default [
  {
    text: "오더",
    value: "order",
    width: "10%",
    align: "center",
  },
  {
    text: "거래처",
    value: "client",
    width: "10%",
    align: "center",
  },
  {
    text: "아이템",
    value: "item",
    width: "25%",
    align: "center",
  },
  {
    text: "입고일",
    value: "date",
    width: "25%",
    align: "center",
  },
  {
    text: "입고 수량",
    value: "quantity",
    width: "25%",
    align: "center",
  },
  {
    text: "상태",
    value: "status",
    width: "25%",
    align: "center",
  },
  {
    text: "비고",
    value: "remark",
    width: "25%",
    align: "center",
  },
];
