import stock from "./stock.vue";
import { mapGetters } from "vuex";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import header from "@/assets/table/stock/stock.js";
export default {
  components: {
    stock,
    LoadingSpinner,
    SnackBar,
    numFilterDialog,
  },
  computed: {
    ...mapGetters({
      stockTotal: "getStockTotal",
    }),
    pageList() {
      return this.$store.state.stockTableList;
    },
    sumStockQuantity() {
      return parseFloat(
        this.pageList
          .reduce(
            (r, c) =>
              r +
              (c.stock_quantity !== "(비어있음)"
                ? !c.unit_sign || c.unit_sign === "y"
                  ? c.stock_quantity
                  : c.stock_quantity * 1.094
                : 0),
            0,
          )
          .toFixed(2),
      );
    },
    sumRemainQuantity() {
      return parseFloat(
        this.pageList
          .reduce(
            (r, c) =>
              r +
              (c.remain_quantity !== "(비어있음)"
                ? !c.unit_sign || c.unit_sign === "y"
                  ? c.remain_quantity
                  : c.remain_quantity * 1.094
                : 0),
            0,
          )
          .toFixed(2),
      );
    },
    sumColorQuantity() {
      return parseFloat(
        this.pageList
          .reduce((r, c) => r + (c.name !== "(비어있음)" && c.name ? 1 : 0), 0)
          .toFixed(2),
      );
    },
    sumItemQuantity() {
      return parseFloat(
        this.pageList
          .reduce((r, c) => r + (c.item !== "(비어있음)" && c.item ? 1 : 0), 0)
          .toFixed(2),
      );
    },
    sumClientQuantity() {
      return parseFloat(
        this.pageList
          .reduce(
            (r, c) => r + (c.client !== "(비어있음)" && c.client ? 1 : 0),
            0,
          )
          .toFixed(2),
      );
    },
  },
  data() {
    return {
      header,
    };
  },
  async created() {
    this.$store.dispatch("SET_API_LOADING", true);
    this.$store.dispatch("GET_SELECT_ORDER_LIST");
    this.$store.dispatch("SET_USE_CLIENTLIST");
    this.$store.dispatch("SET_ITEMLIST");
    this.$store.dispatch("SET_API_LOADING", false);
  },
  beforeDestroy() {
    // this.$store.commit("setOrderList");
    this.$store.commit("setItemList");
  },
};
